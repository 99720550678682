.ExpensesDetails-container {
    width: 90%;
    color: #00b088;
}

.ExpensesDetails-header,
.ExpensesDetails-firstInputRow,
.ExpensesDetails-firstInputRow {
    margin-bottom: 2em;
}

.ExpensesDetails-header {
    margin-left: 1em;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.ExpensesDetails-backButton {
    margin-right: 1em;
    display: flex;
    flex-direction: row;
}

.ExpensesDetails-backArrow {
    transform: rotate(180deg);
    width: 12px;
    margin-top: auto;
    margin-bottom: auto;
    display: block;
}

.ExpensesDetails-title {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.ExpensesDetails-content {
    margin-left: 1em;
}

.ExpensesDetails-firstInputRow {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 3em;
}

.ExpensesDetails-secondInputRow {
    margin-bottom: 5em;
}

.ExpensesDetails-thirdInputRow {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 5em;
}

