.EmployeeContacts-container {
  display: flex;
  flex-direction: column;
}
.EmployeeContacts-name {
  font-size: 2em;
  font-weight: bold;
  color: #3aafc9;
}
.EmployeeContacts-data {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.EmployeeContacts-data-title {
  font-size: 1.5em;
  color: #3aafc9;
}
.EmployeeContacts-content {
  display: flex;
}
.EmployeeContacts-data-firstRow {
  font-size: 1em;
  color: #fdfdfd;
}
.EmployeeContacts-data-secondRow {
  font-weight: bold;
  font-size: 1.5em;
  color: #fdfdfd;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}
