
.employee-avatar-close-container {
  display: block;
  text-align: center;
  position: relative;
  margin: 1em;
}

.employee-avatar-close-image {
  border-radius: 50%;
  border: 1px;
  border-style: solid;
  border-color: #3AAFC9;
  justify-content: center;
  height: 60px;
  width: 60px;
  background-color: #676cdb;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
