.Training-container {
  padding: 2em 4em;
}
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  cursor: pointer;
}
.header-title {
  font-size: 28px;
  font-weight: bold;
  color: #3aafc9;
}
.header-description-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5%;
}
.header-description {
  display: flex;
  flex-direction: column;
}
.header {
  color: #ffff;
  font-size: 1em;
}
.description {
  color: #ffff;
  font-size: 1.5em;
  font-weight: 700;
}
.certificate-container {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
