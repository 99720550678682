.vacationDetails-Parent {
  position: relative;
  padding: 2em 8em;
}
.vacation-info {
  display: flex;
  flex-direction: column;
}
.vacations-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.vacations-second-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #fdfdfd;
  margin-top: 1em;
}
.period-vacations-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.period-vacations {
  color: #fdfdfd;
  font-size: 1em;
  flex: 2;
}
.days-vacations {
  color: #fdfdfd;
  font-size: 1em;
  flex: 1;
}
.approved-vacations {
  color: #00a707;
  font-size: 1em;
  flex: 0.5;
}
.not-approved-vacations {
  color: #ff0000;
  font-size: 1em;
  flex: 0.5;
}

.submitted-vacations {
  color: #676cdb;
  font-size: 1em;
  flex: 0.5;
}
.secondary-text-longer {
  color: #fdfdfd;
  font-size: 1.2em;
  flex: 2;
}
.secondary-text {
  color: #fdfdfd;
  font-size: 1.2em;
  flex: 1;
}
.secondary-text-shorter {
  color: #fdfdfd;
  font-size: 1.2em;
  flex: 0.5;
}
.vacations-year-total {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.total-vacations-container {
  display: flex;
  flex-direction: row;
}
.vacation-container {
  display: flex;
  flex-direction: column;
}
.HR-Vac-List-Container {
  display:flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1em;
  font-size: 1.1em;
}
.vacation-odd-container {
  background-color: #1d2332;
}

.content-year-vacations {
  color: #fdfdfd;
  font-weight: 700;
  font-size: 1.5em;
}
.content-total-vacations {
  color: #fdfdfd;
  font-weight: 700;
  font-size: 1.5em;
  margin-left: 2%;
}
.content-vacations {
  color: #fdfdfd;
  font-weight: 700;
  font-size: 1.5em;
  flex: 1;
}
.total-vacations {
  font-size: 1.2em;
  color: #fdfdfd;
  flex: 1;
}
.schedulled-vacations {
  font-size: 1.2em;
  color: #209973;
  flex: 1;
}
.remaining-vacations {
  font-size: 1.2em;
  color: #677eb8;
  flex: 1;
}

.Vac-Summary-Container {
  display: flex; 
  flex-direction: row; 
  gap: 1em;
}

.Vac-Summary-Item-Container {
  background-color: transparent;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
  margin-top: 2em;
}

.Vac-Summary-Item-title {
  padding: 16px 20px 8px 20px;
  font-weight: 700;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Vac-Summary-Item-value {
  padding: 8px 20px 16px 20px;
  background-color: #676cdb;
  color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
