.VacationsCreate-container {
  width: 100%;
}

.VacationsCreate-submitContainer {
  padding: 1em;
}

.VacationsCreate-submitButton {
  background: #209973 !important;
  border: 3px solid #6accb5 !important;
  border-radius: 20px !important;
}

.VacationsCreate-expensesContainer {
  padding-top: 1em;
  padding-right: 1em;
}

.VacationsCreate-formContainer {
  height: 71.9vh;
  background-color: #ffffff;
}

.VacationsCreate-calendarContainer {
  padding: 1em;
  background-color: #ffffff;
}

.VacationsCreate-addExpenseContainer {
  display: flex;
  justify-content: center;
  padding: 2em;
  bottom: 0;
}

.VacationsCreate-addExpense {
  width: 213px;
  height: 42px;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 3px solid #000000 !important;
  border-radius: 20px !important;
}

.VacationsCreate-addExpense:hover {
  background-color: #209973 !important;
  color: white !important;
  border: 3px solid #6accb5 !important;
}
