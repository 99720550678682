.ServiceGrid-container {
    display: grid;
    justify-items: center;
    row-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    text-decoration: none;
}

.ServiceGrid-link {
    text-decoration: none;
}