.TestimonialGrid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  row-gap: 2em;
}

.TestimonialGrid-link {
  text-decoration: none;
}
