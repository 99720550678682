.BlogPostAuthor-container {
  display: flex;
  align-items: center;
}

.BlogPostAuthor-info .BlogPostAuthor-image {
  border: 2px solid #66cdb5;
}

.BlogPostAuthor-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}

.BlogPostAuthor-footer {
  display: flex;
  flex-direction: column;
}

.BlogPostAuthor-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 143%;
  color: #f9f9f9;
}

@media (max-width: 500px) {
  .BlogPostAuthor-name {
    font-size: 16px;
    line-height: 143%;
  }
}
