.CaseStudyDetailsResults ul {
    grid-template-rows: 1fr auto;
    break-inside: avoid;
    column-count: 2;
    column-gap: 200px;
}

.CaseStudyDetailsResults-studyResults {
    width: 100%;
}

.CaseStudyDetailsResults {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.CaseStudyDetailsResults h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 138.5%;
}

.CaseStudyDetailsResults h2 span {
  color: #66cdb5;
}

.CaseStudyDetailsResults-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

/* Responsive */

@media (max-width: 1440px) {
    .CaseStudyDetailsResults ul {
      column-gap: 150px;
    }
  
    .CaseStudyDetailsResults ul li {
      font-size: 18px;
    }
  }
  
  @media (max-width: 1100px) {
    .CaseStudyDetailsResults ul {
      column-gap: 100px;
    }
  
    .CaseStudyDetailsResults ul li {
      font-size: 16px;
    }
  }
  
  @media (max-width: 750px) {
    .CaseStudyDetailsResults ul {
      column-count: 1;
    }
  
    .CaseStudyDetailsResults ul li {
      font-size: 15px;
    }
  }
  
  @media (max-width: 500px) {
    .CaseStudyDetailsResults ul li {
      font-size: 12px;
    }
  }