.ImageInputSquareAfterAdd-uploadContainer {
  border-radius: 32px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.ImageInputSquareAfterAdd-deleteButton {
  height: 26px;
  width: 26px;
  top: 0;
  right: 0;
  position: absolute;
}
