.VacancyEditPage-pageEditDiv .Page-menuSwitch_element,
.VacancyEditPage-pageEditDiv .Page-menuSwitch_activeElement {
  float: left;
  margin-right: 2em;
  font-size: 20px;
  color: #827676;
  font-weight: 400;
  cursor: pointer;
}

.VacancyEditPage-pageEditDiv .PageCreate-tabs {
  display: flex !important;
  align-items: center;
}
