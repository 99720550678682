.add-button {
  border: 3px solid;
  font-weight: 700;
  height: fit-content;
  border-radius: 32px;
  padding: 0.5em 1.5em;
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1em;
  background-color: transparent;
}

.add-buttonIO {
  border-color: #00b189;
  color: #fff;
}

.add-buttonIO:hover {
  background-color: #fff;
  color: #00b189;
}

.add-buttonHR {
  border-color: #676cdb;
  color: #fff;
}

.add-buttonHR:hover {
  background-color: #fff;
  color: #676cdb;
}

.add-buttonFinance {
  border-color: #676cdb;
  color: #fff;
}

.add-buttonFinance:hover {
  background-color: #fff;
  color: #676cdb;
}
