.item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.item-row{
    display:flex;
    flex-direction: row;
    border-bottom: 2px solid #fdfdfd;
    justify-content: center;
    align-items: center;
}

.item-row-cursor {
  cursor: pointer;
}

.item-column {
  flex: 1;
  color: #fff;
}

.icons-column {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.icon-button {
  cursor: pointer;
}

.TrainingList-header-container {
  display: flex;
  margin-bottom: 2em;
  justify-content: space-between;
  align-items: center;
}

.EmployeeInfo-name {
  font-size: 2em;
  font-weight: bold;
  color: #3aafc9;
}
.certificate-color{
  color:#676CDB;
}

.Training-container {
  padding: 2em 4em;
}
