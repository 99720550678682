.BlogPostCategories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

  
.BlogPostCategories-single {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 120%;
    text-transform: uppercase;
    color: #262626;
    background: #66cdb5;
    padding: 6px 15px;
    border-radius: 50px;
}

@media (max-width: 500px) {
    .BlogPostCategories-single {
      font-size: 7px;
      line-height: 120%;
    }
}