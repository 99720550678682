.TimeReportRow-th {
    text-align: start;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #00b189;
    background: #fff;
    border-bottom: 2px solid #aba3a3;
    min-width: 20px;
}
.TimeReportRow-tr {
    height: 75px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #00b189;
    background: #fff;
    border-bottom: 2px solid #aba3a3;
    min-width: 20px;
}

.TimeReportRow-code {
    border-bottom: 2px solid #d5d0d0;
}

.TimeReportRow-search {
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    overflow: hidden;
    word-wrap: break-word;
    text-align: left;
    margin-right: 10px;
    min-width: 200px;
}

.TimeReportRow-td {
    text-align: center;
    border-bottom: 2px solid #d5d0d0;
    padding: 0;
}

.TimeReportRow-input {
    padding: 0;
    border: 0;
    text-align: center;
    height: 40px;
    width: 100%;
    border-left: 1px solid #aba3a3;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.TimeReportRow-input:focus {
    outline: none;
    background-color: #fff;
}
.TimeReportRow-input:disabled {
    background-color: #b7f0e3;
    color: #787878;
}

.TimeReportRow-deleteRow svg {
    height: 20px;
    width: 20px;
    fill: #d32f2f !important;
}

.TimeReportRow-error {
    color: #d32f2f !important;
}

.TimeReportRow-vacationsWBSTitle {
    color: #787878;
}

.TimeReportRow-deleteRow {
    min-width: 50px;
}