.project-details-main-section {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
}

.project-details-image {
  padding: 0.5em;
  margin-top: 2em;
  margin-left: 1.5em;
}

.project-details-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 1em;
  margin-left: 2em;
  flex-wrap: wrap;
}

.project-details-section-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
}

.project-details-image-tag {
  width: 382px;
  height: auto;
}

.project-details-attribute {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-details-attribute-label {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.project-details-attribute-value {
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
}

.project-details-approver-avatars {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: .3em;
}

.HR-message-success {
  text-align: center;
  color: white;
  background-color: #209973;
  font-weight: 500;
  padding: 1em 6em;
  border-radius: 20px;
  animation: fadeOut ease 2s;
}

.HR-message-fail {
  text-align: center;
  color: white;
  background-color: red;
  font-weight: 500;
  padding: 1em 6em;
  border-radius: 20px;
  animation: fadeOut ease 2s;
}

.HR-message-container {
  position: absolute;
  top: -30px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}