.VacationsSummaryCount-container {
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.VacationsSummaryCount-addButtonContainer {
  margin-left: auto;
}

.VacationsSummaryCount-balanceContainer {
  display: flex;
  flex-direction: row;
  height: 12vh;
}

.VacationsSummaryCount-countContainer {
  margin-right: 10px;
  width: 14vw;
}
