.CaseStudyDetailsChallenge-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  position: relative;
  z-index: 1;
}

.CaseStudyDetailsChallenge-bodyText {
  margin-left: 4em;
  position: relative;
  z-index: 2;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 174.5%;
  color: #231f20;
}

.CaseStudyDetailsChallenge-title {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 138.5%;
  color: #fafafa;
  background-color: #231f20;
  border-radius: 34px;
  padding: 45px 45px 35px 50px;
  max-width: 425px;
  height: fit-content;
  position: relative;
  z-index: 2;
}

.CaseStudyDetailsChallenge-title h3 {
  max-width: 360px;
}

/* Responsive */

@media (max-width: 1440px) {
  .CaseStudyDetailsChallenge-bodyText {
    margin-left: 3em;
    font-size: 18px;
  }

  .CaseStudyDetailsChallenge-title {
    max-width: 350px;
    padding: 35px 35px 25px 40px;
    font-size: 28px;
  }
}

@media (max-width: 1000px) {
  .CaseStudyDetailsChallenge-bodyText {
    margin-left: 3em;
    font-size: 16px;
  }

  .CaseStudyDetailsChallenge-title {
    max-width: 300px;
    padding: 35px 35px 25px 40px;
    font-size: 24px;
  }
}

@media (max-width: 950px) {
  .CaseStudyDetailsChallenge-title {
    max-width: 250px;
    padding: 35px 35px 25px 40px;
    font-size: 24px;
  }
}

@media (max-width: 750px) {
  .CaseStudyDetailsChallenge-content {
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .CaseStudyDetailsChallenge-bodyText {
    margin-left: 0em;
    max-width: 100%;
    margin-top: 20px;
  }

  .CaseStudyDetailsChallenge-title {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .CaseStudyDetailsChallenge-title {
    font-size: 23px;
    line-height: 138.5%;
    padding: 35px 35px 25px 30px;
  }
  .CaseStudyDetailsChallenge-bodyText {
    margin-left: 0em;
    font-size: 12px;
    line-height: 174.5%;
  }
  .CaseStudyDetailsChallenge-content::before {
    font-size: 100px;
    line-height: 94.5%;
    top: -70px;
    left: 0;
  }
}
