.ImageInputSquareAdd-uploadContainer {
    border-radius: 32px;
    border-width: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ImageInputSquareAdd-uploadContainerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ImageInputSquareAdd-uploadIcon {
    width: 10em;
}