.CaseStudyCreateEditSummary-container {
    display: flex;
    flex-direction: row;
}

.CaseStudyCreateEditSummary-container_leftSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
}

.CaseStudyCreateEditSummary-container_rightSide {
    margin-left: 5em;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.CaseStudyCreateEditSummary-dateContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}