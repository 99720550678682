.CaseStudyDetails-header {
  position: relative;
  z-index: 1;
}

.CaseStudyDetails-header::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #231f20a6;
  z-index: 1;
}

.CaseStudyDetails-info{
  width: 100%;
  height: 100%;
}


.CaseStudyDetails-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.CaseStudyDetails-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 138.5%;
}

.CaseStudyDetails-container h2 span {
  color: #66cdb5;
}