.EmployeDetails-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #29313d;
  padding: 1.5em;
  width: 270px;
  height: 270px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
}
.EmployeDetails-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
  align-items: center;
  gap: 1em;
}

.EmplyeeDetails-employeesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3em;
}