.BlogPostCreateEditAuthor-container {
    display: flex;
    flex-direction: row;
}

.BlogPostCreateEditAuthor-container_rightSide {
    margin-left: 5em;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.BlogPostCreateEditAuthor-dateContainer {
    display: flex;
    flex-direction: row;
}