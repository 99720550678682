.Controller-Button {
    padding: 10px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 28px;
    line-height: 18px;
    cursor: pointer;
}

.Controller-Button-Active {
    border: 2px solid #676cdb;
    background-color: #fff;
    color: #676cdb;
}

.Controller-Button-Active:hover {
    border-color: #fff;
    background-color: #676cdb;
    color: #fff;
}

.Controller-Button,
.No-Select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Controller-Buttons-Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
}