.HRPage-kodlyIcon {
  display: flex;
  background-color: #676CDB !important;
  height: 90px;
  align-items: center;
  cursor: pointer;
}

.project-page-container {
    display: flex;
    width: 100%;
    align-items: center;
}

.project-page-element,
.project-page-active-element {
    float: left;
    margin-left: 5%;
    font-size: 24px;
    color: white;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
}

.project-page-active-element {
    color: #676CDB;
    font-weight: 700;
}

.project-page-element:hover {
    color: #676CDB;
    font-weight: 700;
}

.create-project-button {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: white;
  background-color: #676CDB;
  border: 2px solid #676CDB;
  border-radius: 20px;
  left: 0;
  right: 0;
  height: 42px;
  width: 200px;
  cursor: pointer;
}

.create-project-button:hover {
  background-color: #3E44D1;
  border: 3px solid #3E44D1;
}

.top-bar-icons {
  display: flex;
  margin-right: 4.5em;
}
