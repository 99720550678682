.EmployeeInfo-container {
  align-items: center;
  position: relative;
}
.EmployeeDetailInfo-container {
  display: flex;
}
.EmployeeInfo {
  display: flex;
  align-items: center;
  gap: 3%;
}
.EmployeeInfo-button {
  display: flex;
}
.EmployeeInfo-name {
  font-size: 2em;
  font-weight: bold;
  color: #3aafc9;
}
.EmployeeInfo-data {
  display: flex;
  flex-direction: column;
}
.EmployeeInfo-data-title {
  font-size: 1em;
  color: #3aafc9;
}
.EmployeeInfo-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.EmployeeInfo-vacations {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.EmployeeInfo-moreInfo {
  color: #676cdb;
  font-size: 0.7em;
  padding-left: 2%;
}
.EmployeeInfo-data-firstRow {
  font-size: 1em;
  color: #fdfdfd;
}
.EmployeeInfo-data-secondRow {
  font-weight: bold;
  font-size: 1.5em;
  color: #fdfdfd;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}
.EmployeeInfo-cursor-pointer {
  cursor: pointer;
}
