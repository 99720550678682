.ExpensesSummaryTable-container {
  margin-top: 3em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ExpensesSummaryTable-header {
  display: flex;
  flex-direction: row;
}

.ExpensesSummaryTable-pagination {
  float: right;
  margin-left: auto;
}

.ExpensesSummaryTable-datePicker {
  float: left;
  margin-right: auto;
  width: 250px;
  margin-bottom: 1em;
}

.ExpensesSummaryTable-tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ExpensesSummaryTable-title {
  color: #00b189;
  size: 29px;
  font-weight: 700;
  margin-bottom: 1em;
}

.ExpensesSummaryTable-table {
  text-align: center;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}

.ExpensesSummaryTable-table thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  border-bottom: 2px solid #b7f0e3;
  color: #00b189;
  background: #f9fbfc;
  min-width: 40px;
  text-align: center;
  width: 25%;
}

.ExpensesSummaryTable-table th {
  text-align: start;
}

.ExpensesSummaryTable-headRow {
  height: 60px;
  text-align: start;
}

.ExpensesSummaryTable-th {
  text-align: start;
}

.ExpensesSummaryTable-table tbody tr td {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  height: 60px;
  word-wrap: break-word;
  width: 25%;
}

.ExpensesSummaryTable-tableRowOdd {
  background-color: #ffffff;
  cursor: pointer;
}

.ExpensesSummaryTable-tableRowEven {
  background-color: #b7f0e3;
  cursor: pointer;
}

.ExpensesSummaryTable-noContentText {
  margin-top: 5em;
  size: 32px;
  color: #444;
  text-align: center;
}
