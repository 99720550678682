.ExpensesSummaryCard-container {
    background-color: #6ACCB5;
    width: 30%;
    border-radius: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.ExpensesSummaryCard-headerTitle {
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

.ExpensesSummaryCard-reviewSection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10%;
}

.ExpensesSummaryCard-reviewItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ExpensesSummaryCard-reviewItemKey {
    font-size: 16px;
}

.ExpensesSummaryCard-reviewItemValue {
    font-size: 18px;
    font-weight: 700;
}