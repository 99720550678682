@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  height: 100vh;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload_button:before {
  content: "\e900";
  color: #fafafa;
}

.icon-plus_button:before {
  content: "\e901";
  color: #fafafa;
}

.icon-next_arrow:before {
  content: "\e902";
  color: #fafafa;
}

.icon-right_arrow:before {
  content: "\e903";
  color: #fafafa;
}

.icon-down_arrow:before {
  content: "\e904";
  color: #fbfbfb;
}

.icon-left_arrow:before {
  content: "\e905";
  color: #fafafa;
}

.icon-white {
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 40px;
}

.icon-green {
  border: 2px solid #00b088;
  border-radius: 50%;
  font-size: 40px;
}

.icon-green:before {
}

.icon-green:hover {
  background: transparent;
  border: 2px solid #00b088;
  cursor: pointer;
}

.icon-green:hover:before {
  color: #00b088;
}

.icon-green:hover::before {
}

.icon-black {
  border: 2px solid #302c2d;
  border-radius: 50%;
  font-size: 40px;
}

.icon-white:hover {
  background: #fff;
  cursor: pointer;
}

.icon-black::before {
  color: #302c2d;
}

.icon-white:hover::before {
  color: #302c2d;
}

.icon-black:hover {
  background: #302c2d;
  cursor: pointer;
}

.icon-black:hover::before {
  color: #fff;
}
