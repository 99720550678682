.TestimonialCreateEdit-row {
  display: flex;
  flex-direction: row;
}

.TestimonialCreateEdit-container {
  display: flex;
  flex-direction: row;
}

.TestimonialCreateEdit-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.TestimonialCreateEdit-containerRightSide {
  margin-left: 5em;
  width: 70%;
  display: flex;
  flex-direction: column;
}
