.employee-avatar-close-container {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 1em;
}

.employee-avatar-close-image {
  border-radius: 50%;
  border: 1px;
  border-style: solid;
  border-color: #3aafc9;
  justify-content: center;
  height: 60px;
  width: 60px;
  background-color: #676cdb;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
  color: #ffffff;
  font-weight: 600;
}
