.CVEducationCertifications-container {
    width: 100%;
  }
  
.CVEducationCertifications-submiteContainer {
  display: flex;
  padding: 1em;
  justify-content: flex-end;
  align-items: flex-end;
}

.CVEducationCertifications-tableContainer {
  padding: 1em;
}

.CVEducationCertifications-dropdowns {
  display: flex;
  flex-direction: column-reverse;
}