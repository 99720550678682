.LabelWithUpperTitle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
}
.LabelWithUpperTitle-title {
  flex: 1;
  font-size: 18;
}
.LabelWithUpperTitle-content {
  flex: 2;
  flex-direction: column;
}
.LabelWithUpperTitle-label {
  font-weight: 700;
  font-size: 24;
}
.LabelWithUpperTitle-role {
  font-size: 14px;
}
