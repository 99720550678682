.BlogPostDetailsHeaderContent-content {
  max-width: 1365px;
  margin: auto;
  padding: 200px 0 70px;
  position: relative;
  z-index: 1;
  border-radius: 32px;
}

.BlogPostDetailsHeaderContent-title {
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 116.5%;
  color: #ffffff;
}

.BlogPostDetailsHeaderContent-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 53px;
  line-height: 116.5%;
  color: #fafafa;
}

.BlogPostDetailsHeaderContent-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  position: relative;
  z-index: 2;
  margin-left: 10px;
  margin-right: 10px;
}

/* Responsive */
@media (max-width: 1800px) {
  .BlogPostDetailsHeaderContent-content {
    max-width: 90%;
  }

  .BlogPostDetailsHeaderContent-title {
    font-size: 50px;
  }

  .BlogPostDetailsHeaderContent-subtitle {
    font-size: 46px;
    line-height: 116.5%;
  }
}

@media (max-width: 1000px) {
  .BlogPostDetailsHeaderContent-title {
    font-size: 46px;
  }

  .BlogPostDetailsHeaderContent-subtitle {
    font-size: 42px;
  }
}

@media (max-width: 500px) {
  .BlogPostDetailsHeaderContent-title {
    font-size: 36px;
    line-height: 116.5%;
  }

  .BlogPostDetailsHeaderContent-subtitle {
    font-size: 32px;
  }

  .blogPostAuthorName {
    font-size: 16px;
    line-height: 143%;
  }
}

.BlogPostDetails-container {
  padding: 100px 0 75px;
}

.BlogPostDetails-container {
  max-width: 1000px;
  margin: auto;
}

.BlogPostDetails-container p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 171%;
  color: #231f20;
}
.BlogPostDetails-container p:not(:last-child) {
  margin-bottom: 20px;
}

.BlogPostDetails-container strong {
  font-weight: bold;
}

.BlogPostDetails-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 49px;
  text-transform: capitalize;
  color: #66cdb5;
  margin: 50px 0;
}

.BlogPostDetails-container h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 42px;
  color: #737171;
  opacity: 0.7;
  margin: 35px 0 15px;
}

.BlogPostDetails-container h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #dcdcdc;
}

.BlogPostDetails-container a {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 171%;
  color: #231f20;
  display: block;
}

.BlogPostDetails-container blockquote {
  background: #eeeeee;
  border-radius: 34px;
  padding: 40px 60px 30px 40px;
}

.BlogPostDetails-container blockquote p {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 139%;
  color: #231f20;
}

.BlogPostDetails-container ul {
  grid-template-rows: 1fr auto;
  break-inside: avoid;
  column-count: 2;
  column-gap: 200px;
}

.BlogPostDetails-container ul li,
.BlogPostDetails-container ol li,
.BlogPostDetails-container ol li {
  margin-left: -17px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 174.5%;
  color: #231f20;
}

.BlogPostDetails-container img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 25px 0 35px;
}

.BlogPostDetails-container table tr td {
  padding-left: 30px;
  border: 1px solid #f2f2f2;
  border-left: none;
}

.BlogPostDetails-container table tr td:not(:first-child) {
  width: 100%;
}

.BlogPostDetails-container table tr td:first-child {
  background: #f2f2f2;
  padding: 10px 30px;
}
.BlogPostDetails-container code p {
  margin: 0;
  line-height: 1.2;
}
.BlogPostDetails-container code p:not(:last-child) {
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .BlogPostDetails-container {
    max-width: 850px;
  }
}

@media (max-width: 1000px) {
  .BlogPostDetails-container {
    max-width: 85%;
    margin: 0 auto;
  }

  .BlogPostDetails-container tr td {
    font-size: 18px;
    line-height: 171%;
  }

  .BlogPostDetails-container p {
    font-size: 18px;
    line-height: 171%;
  }

  .BlogPostDetails-container h2 {
    font-size: 30px;
    line-height: 49px;
  }

  .BlogPostDetails-container h3 {
    font-size: 26px;
    line-height: 42px;
  }

  .BlogPostDetails-container h4 {
    font-size: 16px;
    line-height: 27px;
  }

  .BlogPostDetails-container a {
    font-size: 12px;
    line-height: 171%;
  }

  .BlogPostDetails-container blockquote p {
    font-size: 20px;
  }

  .BlogPostDetails-container ul {
    column-gap: 150px;
  }

  .BlogPostDetails-container ul li,
  .BlogPostDetails-container ol li,
  .BlogPostDetails-container tr td {
    font-size: 18px;
    line-height: 174.5%;
  }

  .BlogPostDetails-container img {
    max-width: 85%;
    object-fit: contain;
  }
}

@media (max-width: 750px) {
  .BlogPostDetails-container p {
    font-size: 16px;
    line-height: 171%;
  }

  .BlogPostDetails-container h2 {
    font-size: 36px;
    line-height: 49px;
  }

  .BlogPostDetails-container h3 {
    font-size: 20px;
    line-height: 42px;
  }

  .BlogPostDetails-container h4 {
    font-size: 14px;
    line-height: 27px;
  }

  .BlogPostDetails-container a {
    font-size: 10px;
    line-height: 171%;
  }

  .BlogPostDetails-container blockquote p {
    font-size: 15px;
  }

  .BlogPostDetails-container ul {
    column-gap: 70px;
  }

  .BlogPostDetails-contentContainer ul li,
  .BlogPostDetails-contentContainer ol li,
  .BlogPostDetails-contentContainer tr td {
    font-size: 16px;
    line-height: 174.5%;
  }

  .BlogPostDetails-contentContainer img {
    max-width: 85%;
    object-fit: contain;
  }
}

@media (max-width: 500px) {
  .BlogPostDetails-contentContainer p {
    font-size: 12px;
    line-height: 171%;
  }

  .BlogPostDetails-container h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .BlogPostDetails-container h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .BlogPostDetails-container h4 {
    font-size: 14px;
    line-height: 174.5%;
  }

  .BlogPostDetails-container a {
    font-size: 7px;
    line-height: 185.5%;
  }

  .BlogPostDetails-container blockquote p {
    font-size: 23px;
    line-height: 139%;
  }

  .BlogPostDetails-container blockquote {
    margin: 0 0 20px;
  }

  .BlogPostDetails-container ul {
    column-count: 1;
  }

  .BlogPostDetails-container ul li,
  .BlogPostDetails-container ol li,
  .BlogPostDetails-container tr td {
    font-size: 12px;
    line-height: 174.5%;
  }
}
