.BlogPostDetailsHeader-container {
    position: relative;
    z-index: 1;
  }
  
.BlogPostDetailsHeader-container::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #231f20a6;
    z-index: 1;
}