.CVPage-main {
  position: relative;
  top: 7%;
}
.CV-message-success {
  text-align: center;
  color: white;
  background-color: #209973;
  font-weight: 500;
  padding: 1em 6em;
  border-radius: 20px;
  animation: fadeOut ease 2s;
}

.CV-message-fail {
  text-align: center;
  color: white;
  background-color: red;
  font-weight: 500;
  padding: 1em 6em;
  border-radius: 20px;
  animation: fadeOut ease 2s;
}

.CV-message-container {
  position: absolute;
  top: -45px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
