.EmployeAvatar-container {
  display: block;
  text-align: center;
}
.EmployeAvatar-image {
  border-radius: 50%;
  justify-content: center;
  height: 105px;
  width: 105px;
  background-color: #676cdb;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 9px;
  margin-bottom: 8px;
}
.EmployeAvatar-name {
  color: #3aafc9;
  font-size: 1em;
  font-weight: bold;
}
.EmployeAvatar-since {
  color: #fff;
  font-size: .75em;
  font-weight: bold;
}
.avatar {
  vertical-align: middle;
  width: 105px;
  height: 105px;
  border-radius: 50%;
}
