.CV-container {
  width: 100%;
  color: #00b088;
  margin-top: 2em;
}

.CV-submitContainer {
  display: flex;
  padding: 1em;
  justify-content: flex-end;
  align-items: flex-end;
}

.CV-deleteButton svg {
  height: 25px;
  width: 25px;
  color: white;
  stroke-width: 1.5;
  stroke: #0f5156;
}
