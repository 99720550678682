.CVEduCert-deleteButton {
    padding: 1em;
    padding-left: 20%;
}

.CVEduCert-box {
    display: flex;
    flex-direction: row;
    column-gap: 20%;
}

.CVEduCert-inputs {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.CVEduCert-1stRow {
    width: 100%;
    column-gap: 20%;
}


.CVEduCert-2ndRow {
    column-gap: 8%;
    width: 100%;
}