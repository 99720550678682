.CaseStudyDetailsSolution-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.CaseStudyDetailsSolution-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 138.5%;
}

.CaseStudyDetailsSolution-container h2 span {
  color: #66cdb5;
}


.CaseStudyDetailsSolution-title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 174.5%;
}

.CaseStudyDetailsSolution-text {
  font-style: normal;
  font-size: 16px;
  line-height: 174.5%;
  margin-top: 60px;
  position: relative;
  z-index: 1;
}

.CaseStudyDetailsSolution-stackTitle {
  font-style: normal;
  font-weight: bold;
  line-height: 84px;
  color: #6e6b6c !important;
  text-align: center !important;
}

.CaseStudyDetailsSolution-stackImage {
  max-width: 90%;
  object-fit: contain;
  margin-top: -10%;
}

/* Responsive */

@media (max-width: 1700px) {
  .CaseStudyDetailsSolution-text {
    font-size: 18px;
    line-height: 174.5%;
  }
}

@media (max-width: 1440px) {
  .CaseStudyDetailsSolution-text {
    font-size: 16px;
    line-height: 174.5%;
  }
}

@media (max-width: 1000px) {
  .CaseStudyDetailsSolution-text {
    font-size: 14px;
    line-height: 174.5%;
  }
}

@media (max-width: 950px) {
  .CaseStudyDetailsSolution-text::after {
    font-size: 150px;
    line-height: 150px;
    bottom: -70%;
    left: 50%;
  }

  .CaseStudyDetailsSolution-text {
    margin-top: 30px;
  }
}

@media (max-width: 750px) {
  .CaseStudyDetailsSolution-text::after {
    font-size: 100px;
    line-height: 94.5%;
    bottom: -50%;
    left: 60%;
  }
}
@media (max-width: 600px) {
  .CaseStudyDetailsSolution-text::after {
    font-size: 100px;
    line-height: 94.5%;
    bottom: -30%;
    left: 60%;
  }
}

@media (max-width: 400px) {
  .CaseStudyDetailsSolution-text::after {
    font-size: 80px;
    line-height: 84.5%;
    bottom: -10%;
    left: 40%;
  }
}
