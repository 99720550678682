.TimeReportRow-weekendInput {
  text-align: center;
  padding: 0;
  border: 0;
  height: 40px;
  width: 100%;
  border-left: 1px solid #aba3a3;
  pointer-events: none;
  background-color: #6accb5 !important;
  color: #6accb5;
}

.TimeReportRow-vacationsInput {
  text-align: center;
  padding: 0;
  border: 0;
  height: 40px;
  width: 100%;
  border-left: 1px solid #aba3a3;
  pointer-events: none;
  background-color: #DCDCDC !important;
  color: #DCDCDC;
}

.TimeReportRow-inputError::placeholder {
  color: red;
}

.TimeReportRow-td {
  text-align: center;
  border-bottom: 2px solid #d5d0d0;
  padding: 0;
}

.TimeReportRow-input {
  padding: 0;
  border: 0;
  text-align: center;
  height: 40px;
  width: 100%;
  border-left: 1px solid #aba3a3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.TimeReportRow-input:focus {
  outline: none;
  background-color: #f9fbfc;
}

.TimeReportRow-input:disabled {
  background-color: #b7f0e3;
  color: #787878;
}

.TimeReportRow-approved {
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
