.HRPage-kodlyIcon {
    display: flex;
    background-color: #676CDB !important;
    height: 90px;
    align-items: center;
}

.Page-gradient {
  border-bottom: 2px solid;
  border-image: linear-gradient(90deg, #00b189 0%, rgba(201, 236, 228, 0.91) 103.42%);
  border-image-slice: 1;
  vertical-align: bottom;
  position: fixed;
  z-index: 1;
  top: 90px;
  width: 100%;
}

.Page-title {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2632;
  font-weight: 700;
  flex-grow: 1;
  pointer-events: none;
}

.HRPage-kodlyIconImg {
    cursor: pointer;
}

.Page-menuSwitch_container {
  display: flex;
  width: 100%;
  align-items: center;
}

.HRPage-menuSwitch_element,
.HRPage-menuSwitch_activeElement {
  margin: 0;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.HRPage-menuSwitch_activeElement {
  color: #676cdb;
  font-weight: 700;
}

.HRPage-menuSwitch_element:hover {
  color: #676cdb;
  font-weight: 700;
}

.HRPage-dashLine {
  border: 0;
  background-color: #676cdb;
  height: 3px;
}

.HRPageCreate-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 7em;
  padding: 2em 8em;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(255, 255, 255),
    rgba(235, 235, 235),
    rgba(215, 215, 215, 0)
  );
  border-image-slice: 1;
}
