.CaseStudyCreateEditSolution-description {
  width: 70%;
}

.BlogPostCreateEditChallenge-container {
  width: 100%;
}

.BlogPostCreateEditChallenge-container .ql-container {
  height: 28em;
  width: 100%;
}
