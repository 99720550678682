.TimeReportHours-container {
  width: 100%;
}

.TimeReportHours-submiteContainer {
  display: flex;
  padding: 1em;
  justify-content: flex-end;
  align-items: flex-end;
}

.TimeReportHours-submiteButton {
  background: #209973 !important;
  border: 3px solid #6accb5 !important;
  border-radius: 20px !important;
}

.TimeReportHours-submiteButtonDisabled {
  background: #fff !important;
  border: 3px solid #dddddd !important;
  border-radius: 20px !important;
  color: dimgrey;
}

.TimeReportHours-calendarContainer {
  padding: 1em;
  background-color: #ffffff;
}

.TimeReportHours-tableContainer {
  padding: 1em;
}

.TimeReportHours-buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.TimeReportHours-tableRow {
  background-color: #b7f0e3 !important;
}

.TimeReportHours-tableHeadContent {
  color: #6accb5 !important;
}

.TimeReportHours-input {
  padding-left: none;
  border-style: none;
}

.TimeReportHours-input:focus {
  outline: none !important;
}

.TimeReportHours-tableContainer {
  overflow-x: scroll;
}

.TimeReportHours-table thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00b189;
  background: #ffffff;
  border-bottom: 2px solid #aba3a3;
  min-width: 20px;
}

.TimeReportHours-headRow {
  height: 36px;
}

.TimeReportHours-th {
  text-align: start;
}

.TimeReportHours-status {
  font-family: "Poppins" !important ;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  vertical-align: center !important;
}

.TimeReportHours-trStatus {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  vertical-align: center !important;
}
