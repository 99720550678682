.EmployeeProject-parent {
  max-width: 800px;
}
.EmployeeProject-container {
  display: flex;
  flex-direction: row;
}
.EmployeeProject {
  display: flex;
  flex-direction: row;
  gap: 4%;
}
.EmployeeProject-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.EmployeeProject-data-closed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15%;
}
.EmployeeProject-logo {
  width: 60%;
  margin-top: 25%;
}
.EmployeeProject-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #29313d;
  padding: 5px;
  width: 239px;
  height: 304px;
  border-radius: 50px;
  align-items: center;
  border: solid 4px #676cdb;
}
.EmployeeProject-current {
  text-align: right;
  color: #ffa979;
  font-size: 14px;
  margin-right: 8px;
  margin-top: 2px;
  font-weight: bold;
  font-style: italic;
}
.EmployeeProject-content-current {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #29313d;
  padding: 5px;
  width: 239px;
  height: 304px;
  border-radius: 20px;
  border: solid 4px #ffa979;
}
.EmployeeProject-title {
  font-size: 1.5em;
  color: #3aafc9;
}
.EmployeeProject-role {
  font-weight: bold;
  font-size: 1.2em;
  color: #fdfdfd;
}
.EmployeeProject-salary {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 50px;
  color: #fdfdfd;
}
.EmployeeProject-type {
  font-weight: bold;
  font-size: 1em;
  color: #fdfdfd;
}
.EmployeeProject-loader {
  min-height: 304px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.EmployeeProject-progress {
  color: white !important;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px;
}

.slick-dots li.slick-active button:before {
  color: #7176dc !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #fdfdfd !important;
  opacity: 100% !important;
}

.slick-prev:before,
.slick-next:before {
  color: #7176dc !important;
}
