.InfoCardSubImageSection-cardContainer {
  background: #ffffff;
  box-shadow: 0 0 39px rgba(0, 0, 0, 0.2);
  border-radius: 34px;
  margin: 0 1em;
  height: 100%;
  text-decoration: none;
}

.InfoCardSubImageSection-cardItemImage {
  overflow: hidden;
  position: relative;
  height: 175px;
  clip-path: view-box;
  margin-bottom: 20px;
  border-radius: 34px 34px 100px 100px;
  width: 350px;
}

.InfoCardSubImageSection-cardItemImage > img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  
}

.InfoCardSubImageSection-cardLink {
  background: #66cdb5;
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  display: flex;
  top: -50px;
  right: -40px;
  transition: 0.5s ease-in-out;
}

.InfoCardSubImageSection-cardLinkArrow {
  max-width: 45px;
  object-fit: contain;
  position: absolute;
  top: 80px;
  left: 25px;
}

.InfoCardSubImageSection-cardIconContainer {
  background-image: url("https://kodly-assets.s3.eu-west-1.amazonaws.com/services/service-item-background.png");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 34px 34px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  height: 174px;
}

.InfoCardSubImageSection-published{
  background-color: #00b189;
  color: white;
}

.InfoCardSubImageSection-notPublished{
  background-color: #dc143c;
  color: white;
}

/* Responsive */
@media (max-width: 1780px) {

  .InfoCardSubImageSection-cardInnerContainer {
    height: calc(100% - 220px);
  }

  .InfoCardSubImageSection-cardLinkArrow {
    top: 76px;
    left: 20px;
    width: 45px;
  }

  .InfoCardSubImageSection-cardContainer:hover .cardLink {
    width: 122px;
    height: 122px;
  }

  .InfoCardSubImageSection-cardItemImage {
    max-height: 170px;
  }
}

@media (max-width: 1440px) {
  .InfoCardSubImageSection-cardContainer:hover .cardLink {
    width: 102px;
    height: 102px;
  }

  .InfoCardSubImageSection-cardLinkArrow {
    top: 69px;
    left: 20px;
    width: 30px;
  }
}



@media (max-width: 850px) {
  .InfoCardSubImageSection-cardContainer:hover .cardLink {
    width: 142px;
    height: 142px;
  }

  .InfoCardSubImageSection-cardLinkArrow {
    width: 45px;
    object-fit: contain;
    position: absolute;
    top: 80px;
    left: 25px;
  }

  .InfoCardSubImageSection-cardContainer {
    max-width: 100%;
  }
}


@media (max-width: 400px) {
  .InfoCardSubImageSection-cardContainer:hover .cardLink {
    width: 102px;
    height: 102px;
  }

  .InfoCardSubImageSection-cardLinkArrow {
    top: 69px;
    left: 20px;
    width: 30px;
  }
}