.employee-avatar-container {
  display: block;
  text-align: center;
  position: relative;
}

.employee-avatar-image {
  border-radius: 50%;
  border: 1px;
  border-style: solid;
  border-color: #3AAFC9;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #676cdb;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number-text {
  font-size: 13px;
  font-weight: 550;
}
