
.project-team-members-table-row-item {
  display: flex;
  flex-direction: row;
  border-top: 2px solid white;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.project-team-table-column, 
.project-team-table-column-name,
.project-team-table-column-cost,
.project-team-table-column-delete {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project-team-table-column-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-column-cost {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-column-delete {
  flex: 0.3;
  align-items: center;
  justify-content: center;
}
