.BubbleSearchInput-reactSearchAutocomplete {
  height: 4em !important;
  width: 14em !important;
  align-items: center;
  justify-content: center;
}

.BubbleInput-grid {
  padding-top: 1.5em;
  display: grid;
  column-gap: 1em;
}

.BubbleInput-addItemsButton {
  background-color: rgb(0, 177, 137) !important;
  border-color: #66cdb5 !important;
  border-style: solid !important;
  border-width: 0.25em !important;
  border-radius: 32px !important;
  font-weight: bold;
  color: black !important;
  margin-top: 0.5em !important;
  width: 20em !important;
}

.BubbleInput-previewItemContainer {
  display: flex !important;
  flex-direction: column !important;
}

.BubbleInput-textField {
  height: 80%;
  width: 75%;
  align-self: center;
}

.css-ttumed {
  padding: 1em !important;
  width: 95% !important;
}

.test {
  width: 100px;
}
