.PageCreateEditSEO-headersLabel {
  margin-left: 1em !important;
  font-weight: 405 !important;
}

.PageCreateEditSEO-headersContainer {
  display: flex;
  flex-direction: row;
}

.PageCreateEditSEO-headers {
  margin-top: 0.5em;
}
