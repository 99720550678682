.CVProfExp-1stRow {
    display: flex;
    flex-direction: row;
    flex: 1;
    column-gap: 3%;
    width: 100%;
}

.CVProfExp-2ndRow {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.CVProfExp-box {
    display: flex;
    flex-direction: row;
    column-gap: 1%;
    width: 100%;
}

.CVProfExp-deleteButton {
    padding: 1em;
}

.CVProfExp-Checkbox {
    white-space: nowrap;
    display: flex;
}

.CVProfExp-inputs {
    width: 100%;
}




  