.ImageInputSquareAfterAddIcon-uploadContainer {
    position: relative;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}

.ImageInputSquareAfterAddIcon-deleteButton {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    height: 26px;
    width: 26px;
    background: #000;
}

.ImageInputSquareAfterAddIcon-deleteButton:hover {
    border: 2px solid #00b189;
    background: #000;
}

.ImageInputSquareAfterAddIcon-deleteIcon {
    color: #00b189;
}