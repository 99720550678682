.card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-left: 2.5em;
}

.project-container {
    display: flex;
    flex-direction: column;
    background-color: #29313D;
    width: 290px;
    padding: 1em;
    color: white;
    border-radius: 20px;
    margin: 1em;
    cursor: pointer;
}

.symbol-state {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.logo-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1.5em;
}

.project-name-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #3AAFC9;
    font-weight: bold;
    margin-top: 1.5em;
}

.start-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.start-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 8em;
}

.team-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.avatar-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.logo-image {
    height: 25px;
    width: auto;
}