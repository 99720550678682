.VacationsSummaryTable-tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.VacationsSummaryTable-title {
  color: #00B189;
  size: 29px;
  font-weight: 700;
  margin-bottom: 1em;
}

.VacationsSummaryTable-table {
  text-align: center;
  width: 100%;
  border-spacing: 0;
}

.VacationsSummaryTable-table thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  border-bottom: 2px solid #b7f0e3;
  color: #00b189;
  background: #f9fbfc;
  min-width: 40px;
  text-align: center;
  width: 25%;
}

.VacationsSummaryTable-table th {
  text-align: start;
}

.VacationsSummaryTable-headRow {
  height: 60px;
  text-align: start;
}

.VacationsSummaryTable-th {
  text-align: start;
}

.VacationsSummaryTable-deleteRow svg {
  height: 20px;
  width: 20px;
  fill: #d32f2f !important;
}

.VacationsSummaryTable-table tbody tr td {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  height: 60px;
  word-wrap: break-word;
  width: 25%;
}

.VacationsSummaryTable-tableRowOdd {
  background-color: #ffffff;
}

.VacationsSummaryTable-tableRowEven {
  background-color: #b7f0e3;
}

.VacationsSummaryTable-statusDefault {
  color: #000000;
}

.VacationsSummaryTable-statusApproved {
  color: #529F46;
}

.VacationsSummaryTable-statusRejected {
  color: #FF0000;
}

.VacationsSummaryTable-submit {
  width: 175px;
  height: 42px;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 3px solid #000000 !important;
  border-radius: 20px !important;
}

.VacationsSummaryTable-submit:hover {
  background-color: #209973 !important;
  color: white !important;
  border: 3px solid #6accb5 !important;
}

.VacationsSummaryTable-noContentText {
  margin-top: 5em;
  size: 32px;
  color: #444;
  text-align: center;
}