.BlogPostImage-container {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(7px);
    background-position: center;
  }