.FileUploadButtonAddMode-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.FileUploadButtonAddMode-title {
    font-size: 16px;
    color: #00b088;
}

.FileUploadButtonAddMode-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 3px solid #000000 !important;
    border-radius: 32px !important;
    text-transform: none;
    min-width: 5em;
    min-height: 3em;
    padding-left: 1em;
    padding-right: 1em;
}
  

.FileUploadButtonAddMode-button:hover {
  background-color: #209973 !important;
  color: white !important;
  border: 3px solid #6accb5 !important;
}