.ImageOnlyCard-container {
  position: relative;
  background-color: #231f20;
  height: 200px;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 32px;
  border-radius: 32px;
  border-color: #66cdb5;
  border-style: solid;
  background-size: contain;
}

.ImageOnlyCard-isPublished,
.ImageOnlyCard-isNotPublished {
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-left: 1em;
  margin-top: 8px;
  border-radius: 8px;
  font-size: 14px;
  width: 100px;
  margin-bottom: 23px;
}

.ImageOnlyCard-isPublished {
  background-color: #00b189;
  color: white;
}

.ImageOnlyCard-isNotPublished {
  background-color: #dc143c !important;
  color: white;
}
