.CVLanguage-input {
    display: flex;
    flex-direction: row;
    width: 50%;
    column-gap: 10%;
}

.CVLanguage-deleteButton {
    padding: 1em;
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
