.ql-toolbar.ql-snow {
  box-sizing: border-box;
  font-family: “Helvetica Neue”, “Helvetica”, “Arial”, sans-serif;
  padding: 8px;
  border: none;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: #c9ece4;
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: white;
  min-height: 12em;
}
.ql-container.ql-snow {
  border: 1px solid #c9ece4;
}
