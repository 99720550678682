.ClientCreateEdit-container {
    display: flex;
    flex-direction: row;
}

.ClientCreateEdit-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
}

.ClientCreateEdit-containerRightSide {
    margin-left: 5em;
    width: 30%;
    display: flex;
    flex-direction: column;
}