.EmployeAvatarSquare-container {
  display: block;
  text-align: center;
}
.EmployeAvatarSquare-imageActive {
  border-radius: 10%;
  justify-content: center;
  height: 20em;
  width: 20em;
  background-color: #676cdb;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.EmployeAvatarSquare-imageInactive {
  border-radius: 10%;
  justify-content: center;
  height: 20em;
  width: 20em;
  background-color: #676cdb;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 51%;
}
