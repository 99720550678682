.CaseStudyCreateEditChallenge-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.CaseStudyCreateEditChallenge-description {
  margin-top: 1em;
  margin-left: 1em;
}

.CaseStudyCreateEditChallenge-description .ql-container {
  height: 20em;
}
