.image-hover-card-container {
  position: relative;
  background-color: #231f20;
  height: 300px;
  width: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-color: #66cdb5;
  border-style: solid;
  border-width: 0.25em;
  border-radius: 32px;
}

.image-hover-card-container-background {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #66cdb5;
  border-radius: 20px;
  align-content: flex-end;
}

.image-hover-card-container-content {
  position: absolute;
  margin-left: 5%;
  top: 35%;
  height: 65%;
}

.image-hover-card-title {
  justify-content: center;
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.image-hover-card-description {
  position: absolute;
  justify-content: left;
  align-items: left;
  justify-items: left;
  left: auto;
  color: black;
  font-size: 1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  margin-top: 10%;
}

.image-hover-card-is-published,
.image-hover-card-is-not-published {
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
  margin-top: 8px;
  border-radius: 8px;
  font-size: 14px;
  width: 100px;
  margin-bottom: 23px;
}

.image-hover-card-is-published {
  background-color: #00b189;
  color: white;
}

.image-hover-card-is-not-published {
  background-color: #dc143c;
  color: white;
}

.image-hover-card-container .CardButtons-mainButtons:hover {
  color: black;
}
