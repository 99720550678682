.UserDetails-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 4vh;
  padding-right: 4vh;
}

.form {
  height: 100%;
  flex: 1;
  display: flex;
}

.UserDetails-container span {
  color: #00b189;
}

.UserDetails-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #00b189;
}

.UserDetails-container h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  margin: 0;
}

.UserDetails-titleContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  gap: 2em;
}

.UserDetails-title {
  font-size: 35px;
}

.UserDetails-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
}

.UserDetails-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 8%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
}

.UserDetails-description {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 10em;
  width: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
}

.UserDetails-backgroundImg {
  height: 75%;
  width: 20%;
  max-width: 50%;
  background-color: #00b189;
  border-radius: 24px;
  padding: 1.25em;
}

.UserDetails-img {
  flex: 1;
  max-height: 25em;
  border-radius: 24px;
}

@media (max-width: 1400px) {
  .UserDetails-backgroundImg {
    width: 30%;
    height: 70%;
  }
}

@media (max-width: 1200px) {
  .UserDetails-content {
    display: block;
    width: 100%;
  }
  .UserDetails-titleContent {
    display: block;
    width: 100%;
  }
  .UserDetails-title {
    width: 100%;
  }
  .UserDetails-description {
    max-width: 100%;
  }
  .UserDetails-column {
    display: block;
    max-width: 100%;
  }
  .UserDetails-backgroundImg {
    position: relative;
    background-image: none;
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 1000px) {
  .UserDetails-content {
    display: block;
    width: 100%;
  }
  .UserDetails-titleContent {
    display: block;
    width: 100%;
  }
  .UserDetails-title {
    width: 100%;
  }
  .UserDetails-description {
    max-width: 100%;
  }
  .UserDetails-column {
    display: block;
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .UserDetails-content {
    display: block;
  }
  .UserDetails-column {
    display: block;
    font-size: 25px;
    line-height: 44px;
  }
  .UserDetails-backgroundImg {
    position: relative;
    background-image: none;
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 550px) {
  .UserDetails-content {
    display: block;
  }
  .UserDetails-column {
    display: block;
    font-size: 20px;
    line-height: 44px;
  }
}

.inputEditButton {
  background-color: #00b189;
  color: #fff;
  border: 0;
  font-weight: 700;
  height: fit-content;
  border-radius: 24px;
  padding: 1em 2em 1em 2em;
}

.inputEditButton:hover {
  background-color: #fff;
  border: 2px solid #00b189;
  color: #00b189;
  cursor: pointer;
}

.input {
  font-size: 1em;
}

.inputEditing {
  color: green;
}

.inputStatic {
  border: 0;
}

.infoSection {
  flex: 1;
  min-width: 20em;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.bioContainer {
  flex: 1;
}

.vacationsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 4em;
}

.headerStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 4em;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.imageContainer {
  flex: 1 1;
  min-width: 20em;
  min-height: 20em;
  padding-top: 1em;
  max-height: 35em;
}

.error {
  color: red;
}
