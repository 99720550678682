.TitleValueSquareBox-container {
  width: 100%;
  height: 100%;
}

.TitleValueSquareBox-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 50%;
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.TitleValueSquareBox-value {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 50%;
  width: 100%;
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  opacity: 0.44;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
