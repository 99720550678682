.CV-formContainer {
  display: flex;
  flex-direction: row;
  gap: 3em;
}

.CVCreateEdit-row {
  display: flex;
  flex-direction: row;
  gap: 20%;
}

.CVCreateEdit-image {
  flex: 1 ;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
}

.CVCreateEdit-containerRightSide {
  flex: 2 ;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.CVCreateEdit-image .ImageInputSquareAdd-uploadContainer {
  align-self: flex-start;
  background-color: transparent !important;
  border: solid #66cdb5; 
}
