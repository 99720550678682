.ExpensesSummary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.ExpensesSummary-newExpenseButton {
    width: 213px;
    height: 42px;
    margin-left: auto;
}

