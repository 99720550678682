.project-team-members-container {
  display: flex;
  flex-direction: column;
}

.project-team-members-top-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  padding: 1em;
  min-height: 6em;
}


.project-team-members-top-section-button {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: white;
  background-color: #1F2632;
  border: 3px solid #676CDB;
  border-radius: 30px;
  height: 42px;
  width: 213px;
  margin-top: 22px;
  cursor: pointer;
}

.project-team-members-top-section-button:hover {
  background-color: #676CDB;
  border: 2px solid #676CDB;
  cursor: pointer;
}

.project-team-members-top-section-button-disabled {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  background-color: #1F2632;
  border: 2px solid grey;
  border-radius: 30px;
  height: 42px;
  width: 213px;
  margin-top: 22px;
}

.project-team-members-top-section-button-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-team-members-table-row {
  display: flex;
  flex-direction: row;
}

.project-team-members-table-row-item {
  display: flex;
  flex-direction: row;
  border-top: 2px solid white;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.project-team-members-bottom-section {
  padding: 16px;
}

.project-team-table-column-header {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-column-header-empty {
  flex: 0.3;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-column, 
.project-team-table-column-name,
.project-team-table-column-cost,
.project-team-table-column-delete {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.project-team-table-column-cost {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-column-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.project-team-table-name {
  margin-left: 0.5em;
}

.project-team-table-column-delete {
  flex: 0.3;
  cursor: pointer;
}
