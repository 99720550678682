.ExpensesCreateEdit-container {
    width: 90%;
    color: #00b088;
}

.ExpensesCreateEdit-title {
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.ExpensesCreateEdit-header,
.ExpensesCreateEdit-firstInputRow,
.ExpensesCreateEdit-firstInputRow {
    margin-bottom: 1em;
}

.ExpensesCreateEdit-header {
    margin-left: 1em;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ExpensesCreateEdit-firstInputRow {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
}

.ExpensesCreateEdit-thirdInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ExpensesCreateEdit-FileUpload {
    margin-left: 3em;
}