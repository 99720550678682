.InfoCardSubBodySection-cardBodyContainer {
  padding: 0 30px 30px;
  display: flex;
  flex-wrap: wrap;
  place-content: space-between;
}

.InfoCardSubBodySection-cardBodyContent {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 143%;
  color: #231f20;
}

.InfoCardSubBodySection-cardBodyTitle {
  flex-direction: column;
  color: #000000;
  font-size: 0.9rem;
  margin: auto 0 0 0;
  font-weight: bold;
}

.InfoCardSubBodySection-cardBodyTitleOneLine {
  text-overflow: ellipsis;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  width: 280px;
}

.InfoCardSubBodySection-cardBodyTitle span {
  color: #00b189;
}

.InfoCardSubBodySection-cardBodyItemFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.InfoCardSubBodySection-cardBodyAuthor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.InfoCardSubBodySection-cardBodyAuthorInfo {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.InfoCardSubBodySection-cardBodyAuthorImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
}

.InfoCardSubBodySection-carBodyText {
  color: #231f20;
  font-size: 0.9rem;
}

@media (max-width: 1100px) {
  .InfoCardSubBodySection-cardBodyItemFooter {
    flex-wrap: wrap;
  }
}

@media (max-width: 420px) {
  .InfoCardSubBodySection-cardBodyAuthorInfo {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 1780px) {
  .InfoCardSubBodySection-cardBodyAuthorImage {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    object-fit: cover;
  }
}

@media (max-width: 1780px) {
  .InfoCardSubBodySection-cardBodyAuthorImage {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    object-fit: cover;
  }
}
