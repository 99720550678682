.create-project-form-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5em;
}

.create-project-image-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 23%;
    padding: 21px;
    margin-right: 1.5em;
}

.create-project-form-container-right {
    display: flex;
    flex-direction: column;
    width: 70%;

}

.create-project-input-field {
    width: calc(100% / 3);
}

.create-project-input-field-startDate {
    width: calc(100% / 3);
    margin-right: 1em;
}

.create-project-row {
    display: flex;
    flex-direction: row;
}

.create-project-row-approvers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}