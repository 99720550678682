.ServiceCreateEdit-container {
  display: flex;
  flex-direction: row;
}

.ServiceCreateEdit-leftSide {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.ServiceCreateEdit-marginLeft {
  margin-left: 1em;
}

.ServiceCreateEdit-rightSide {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 5em;
}

.ServiceCreateEdit-righSide_item {
  margin-top: 1em;
}
