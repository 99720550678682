.TimeReportTable-buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.TimeReportTable-tableRow {
  background-color: #b7f0e3 !important;
}

.TimeReportTable-tableHeadContent {
  color: #6accb5 !important;
}

.TimeReportTable-weekend {
  background-color: #00b189 !important;
}

.TimeReportTable-tableContainer {
  overflow-x: scroll;
}

.TimeReportTable-table {
  width: 99.9%;
  border-spacing: 0;
}

.TimeReportTable-table thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00b189;
  background: #fff;
  border-bottom: 2px solid #aba3a3;
  min-width: 20px;
}

.TimeReportTable-headRow {
  height: 36px;
}

.TimeReportTable-deleteTable {
  text-align: center;
}

.TimeReportTable-totalRowHead {
  margin-top: 1em;
}

.TimeReportTable-totalRowHeadWBS {
  width: 220px;
}

.TimeReportTable-totalRowHeadDelete {
  width: 50px;
}

.TimeReportTable-totalRow {
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #b7f0e3 !important;
  border-left: 1px solid #aba3a3;
  color: #787878;
}

.TimeReportTable-weekendTotalRow {
  color: #b7f0e3 !important;
  background-color: #b7f0e3 !important;
  border-left: 1px solid #aba3a3;
}

.TimeReportTable-addRowButton {
  padding-top: 3em;
}

.TimeReportTable-totalRowHead-wbs {
  min-width: 200px;
}

.TimeReportTable-totalRowHead-delete {
  min-width: 20px;
}