.HRSidebar-sidebar {
    display: flex;
    flex-direction: column;
    height: calc(100% - 90px);
    width: 100%;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: space-between;
    background-color: #676CDB;
  }
  
  .HRSidebar-sidebar span {
    color: white;
  }
  
  .HRSidebar-sidebar li {
    margin: 0.5em 0;
  }
  
  .HRSidebar-sidebar li svg {
    width: 28px !important;
    height: 28px !important;
  }
  
  .HRSidebar-sidebar li .sidebarKodlyText svg {
    width: auto !important;
    height: auto !important;
  }
  
  .sidebarKodlyText {
    height: 26px;
  }
  
  .HRSidebar-sidebar .activeLink {
    background-color: #0f5156;
  }
  
  .HRSidebar-kodlyIcon {
    cursor: pointer;
  }
  
  .HRSidebar-logout {
    cursor: pointer;
    font-family: "Noto Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    bottom: 1.6em;
    left: 0.8em;
    position: relative;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #231f20;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #676CDB;
    border-radius: 10px;
  }
  
  .HRSidebar-itemIcon {
    min-width: 0 !important;
    justify-content: flex-start !important;
    width: 32 !important;
    height: 32 !important;
  }