.EmployeeDetails-Parent {
  position: relative;
  padding: 2em 8em;
  height: 100%;
}
.EmployeeDetails-container {
  display: flex;
  flex-direction: column;
}
.EmployeDetails-content {
  display: flex;
  justify-content: space-between;
}
.EmployeDetails-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.EmployeDetails-curriculum {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}
.Modal-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal-popup {
  position: relative;
  padding: 2em 4em;
  width: 70%;
  height: 60%;
  background-color: #1f2632;
  opacity: 100%;
  border: 4px solid #fff;
  border-radius: 50px;
}
.Modal-container-cv {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal-popup-cv {
  position: relative;
  padding: 2em 4em;
  width: 35%;
  height: 35%;
  background-color: #1f2632;
  opacity: 100%;
  border: 4px solid #fff;
  border-radius: 50px;
}
.Clear-icon {
  color: #3aafc9;
  cursor: pointer;
  width: 1.5em !important;
  height: 1.5em !important;
  align-self: flex-end;
}
.Popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.Popup-title {
  color: #fdfdfd;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.Popup-content-cv {
  display: flex;
  flex-direction: column;
  margin-top: 1.3em;
}
.Popup-header {
  display: flex;
  flex-direction: column;
  color: #3aafc9;
  font-size: 1.5em;
}
.Popup-header-cv {
  display: flex;
  flex-direction: column;
  color: #3aafc9;
  font-size: 1.5em;
  font-weight: 700;
}
.Popup-year {
  font-size: 1.1em;
  flex: 1;
}
.Popup-schedule-days {
  color: #ff8a00;
  font-size: 1.1em;
  flex: 1;
}
.Popup-approved-days {
  color: #209973;
  font-size: 1.1em;
  flex: 1;
}
.Popup-remaining-days {
  color: #677eb8;
  font-size: 1.1em;
  flex: 1;
}
.Popup-total-days {
  color: #fdfdfd;
  font-size: 1.1em;
  flex: 1;
}
.Popup-container {
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 2px solid #fdfdfd;
  align-items: center;
}
.Popup-content-vacations {
  flex: 1;
  color: #fff;
  padding: 2em 0em;
}
.Popup-list-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.EmployeeCurriculum-container {
  background-color: #29313d;
  width: 213px;
  height: 42px;
  border-radius: 20px;
  border: solid #676cdb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EmployeeCurriculum-container-popup {
  background-color: #29313d;
  width: 35%;
  height: 15%;
  border-radius: 20px;
  border: solid #676cdb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32%;
  margin-top: 1.5em;
}
.EmployeeCurriculum {
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  color: #fdfdfd;
  cursor: pointer;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  color: #fdfdfd;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  height: 1.5em;
  width: 1.5em;
  background-color: #eee;
  border-radius: 15px;
}
.checkbox-element {
  display: flex;
  flex-direction: row;
}
