.CardButtons-buttons {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
}

.CardButtons-buttons svg {
  width: 24px;
  height: 24px;
}

.CardButtons-mainButtons {
  color: #faf9f6;
  margin-right: 8px;
}

.CardButtons-mainButtons:hover {
  color: #00b189;
}

.CardButtons-publishButton {
  color: #00b189 !important;
}

.CardButtons-unPublishButton {
  color: #d32f2f !important;
}

.CardButtons-duplicateButton {
  color: #00b189 !important;
}

.CardButtons-deleteButton {
  color: #d32f2f !important;
}
