.FileDownloadButton-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.FileDownloadButton-title {
    font-size: 16px;
    color: #00b088;
}

.FileDownloadButton-buttonContainer {
    background-color: red;
}

.FileDownloadButton-containerButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: -3em;
}

.FileDownloadButton-button {
    cursor: pointer;
    background-color: #209973 !important;
    border: 3px solid #6accb5 !important;
    border-radius: 32px !important;
    width: 20em;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FileDownloadButton-button:hover {
    background-color: #0d3d2e !important;
}

.FileDownloadButton-buttonContent {
    width: 100%;
    color: #fff !important;
    text-transform: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.FileDownloadButton-deleteButtonContainer {
    height: 120%;
}

.FileDownloadButton-deleteButton {
    height: 26px;
    width: 26px;
    background: #000 !important;
}

.FileDownloadButton-deleteButton:hover {
    border: 2px solid #00b189;
    background: #000 !important;
}

.FileDownloadButton-deleteIcon {
    color: #00b189;
}
