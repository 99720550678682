.VacancyCard-container {
  background: #c9ece4;
  border-radius: 32px;
  width: 350px !important;
  position: relative;
}

.VacancyCard-containerDisplay {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px 33px;
  align-items: center;
}

.VacancyCard-container:hover .icon-black::before {
  color: #fff;
}
.VacancyCard-container:hover .icon-black {
  background: #302c2d;
  cursor: pointer;
}

.VacancyCard-containerDnD {
  background: #c9ece4;
  border-radius: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px 33px;
  align-items: center;
  margin: 1em;
}

.VacancyCard-description {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-decoration: none;
}

.VacancyCard-description .VacancyCard-role {
  padding-bottom: 8px;
  color: #231f20;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 75%;
}

.VacancyCard-descriptionDnD {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.VacancyCard-info {
  color: #231f20;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.VacancyCard-published {
  background-color: #00b189;
  color: white;
}

.VacancyCard-notPublished {
  background-color: #dc143c;
  color: white;
}

.VacancyCard-container .CardButtons-mainButtons {
  color: #231f20;
  margin-right: 1em;
}

.VacancyCard-container .CardButtons-mainButtons:hover {
  color: #00b189;
}

.css-1hmkfx-MuiTypography-root {
  line-height: 1.9 !important;
  height: 21px !important;
  padding: 0 !important;
}
