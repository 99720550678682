.AddCircleOutlineButton-enabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 3px solid #000000 !important;
    border-radius: 20px !important;
  }
  
.AddCircleOutlineButton-disabled {
    background-color: #ffffff !important;
    color: #dddddd !important;
    border: 3px solid #dddddd !important;
    border-radius: 20px !important;
}

.AddCircleOutlineButton-enabled:hover {
  background-color: #209973 !important;
  color: white !important;
  border: 3px solid #6accb5 !important;
}