.CaseStudyCreateEditSolution-container {
  display: flex;
  flex-direction: row;
}

.CaseStudyCreateEditSolution-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
}

.CaseStudyCreateEditSolution-description {
  margin-left: 5em;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.CaseStudyCreateEditSolution-description .ql-container {
  height: 27em;
}
