.ProfessionalExperiences-container {
  width: 100%;
}

.ProfessionalExperiences-submiteContainer {
display: flex;
padding: 1em;
justify-content: flex-end;
align-items: flex-end;
}

.ProfessionalExperiences-tableContainer {
padding: 1em;
}

.CVProfessionalExperiences-dropdowns {
  display: flex;
  flex-direction: column-reverse;
}

