.AutoComplete-backgroundItemContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-top: 1.5em;
  background-color: #fafafa;
  border-radius: 32px;
  position: relative !important;
}

.AutoComplete-deleteButton {
  position: absolute !important;
  right: -0.3em !important;
  top: -0.3em !important;
  height: 1em !important;
  width: 1em !important;
  background: black !important;
  z-index: 1;
}

.AutoComplete-deleteButton:hover {
  opacity: 50% !important;
}

.AutoComplete-clearIcon {
  color: #66cdb5 !important;
}

.AutoComplete-container {
  width: 14em !important;
  border-radius: 32px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 32px !important;
}

.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  visibility: hidden !important;
}

.css-1t2l1f-MuiFormLabel-root-MuiInputLabel-root {
  color: #00b189 !important;
}

.css-1t2l1f-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #66cdb5 !important;
}

.css-165tnrm-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #00b189 !important;
  border-width: 2px !important;
}

.css-165tnrm-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #66cdb5 !important;
  border-width: 2px !important;
}
