.ExpenseDetailsKeyValueItem-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.ExpenseDetailsKeyValueItem-key {
    font-size: 16px;
    color: #00B189;
}

.ExpenseDetailsKeyValueItem-value {
    font-size: 18px;
    font-weight: 500;
    color: inherit;
}

.ExpenseDetailsKeyValueItem-multiLineValue {
    font-size: 18px;
    font-weight: 500;
    color: inherit;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}