.project-details-members-main-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2.5em;
  min-height: 70vh;
}

.project-details-members-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #3AAFC9;
  margin-bottom: .3em;
}

.project-details-members-active,
.project-details-members-old {
  display: flex;
  flex-direction: column;
}

.project-details-members-old {
  margin-top: auto;
}

.project-details-members-table-row {
  display: flex;
  flex-direction: row;
}

.project-details-members-column-header {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

