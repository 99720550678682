.InfoCard-withIconContainer {
  background: #ffffff;
  box-shadow: 0 0 39px rgba(0, 0, 0, 0.2);
  border-radius: 34px;
  margin: 0 1em;
  height: 100%;
  text-decoration: none;
  width: 350px;
  height: 95%;
  position: relative;
}

.InfoCard-withIconItem {
  border-radius: 34px 34px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  background-image: url("https://kodly-assets.s3.eu-west-1.amazonaws.com/services/service-item-background.png");
  background-position: center;
  background-size: 450px;
  background-repeat: no-repeat;
}

.InfoCard-backgroundImage {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

/* Responsive */
@media (max-width: 1780px) {
  .InfoCard-withIconContainer:hover .cardLink {
    width: 122px;
    height: 122px;
  }
}

@media (max-width: 1440px) {
  .InfoCard-withIconContainer:hover .cardLink {
    width: 102px;
    height: 102px;
  }
}

@media (max-width: 850px) {
  .InfoCard-withIconContainer:hover .cardLink {
    width: 142px;
    height: 142px;
  }

  .InfoCard-withIconContainer {
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .info-card-with-icon-container:hover .cardLink {
    width: 102px;
    height: 102px;
  }
}
.InfoCard-image{
  width:100%;
}
