.CVTechnology-box{
    display: flex;
    flex-direction: row;
}

.CVTechnology-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 25%;
}


.CVTechnology-deleteButton {
    padding: 1em;
}