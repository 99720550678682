.Training-container {
  padding: 2em 4em;
}

.Training-Details-Create-Header {
  font-size: 2em;
  margin: 0;
  font-weight: bold;
  color: #fff;
}

.Training-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Training-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 4em;
}
.forms {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  gap: 10%;
}
.create-project-image-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 23%;
  padding: 21px;
  margin-right: 1.5em;
}
.cert-label {
  color: #fff;
  margin-top: 5%;
}
