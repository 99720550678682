.BlogPostInfo-footerInfo img {
    margin-right: 10px;
}
  
.BlogPostInfo-footerInfo {
    display: flex;
    align-items: center;
}

.BlogPostInfo-read,
.BlogPostInfo-date {
  display: flex;
  align-items: center;
}

.BlogPostInfo-readFooter,
.BlogPostInfo-dateFooter {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 143%;
  color: #cccccc;
  opacity: 0.94;
}

.BlogPostInfo-dateFooter {
  margin-right: 30px;
}

@media (max-width: 650px) {
    .BlogPostInfo-footerInfo {
        order: 2;
    }

    .BlogPostInfo-footerInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 500px) {  

    .BlogPostInfo-footerInfo img {
      margin-right: 5px;
      width: 20px;
    }

    .BlogPostInfo-date {
        margin-right: 0px;
    }

    .BlogPostInfo-read,
    .BlogPostInfo-date {
      font-size: 12px;
      line-height: 143%;
    }
}


